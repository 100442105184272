@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark light;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer base {
  input:not([type='radio']):not([type='checkbox']) {
    @apply rounded !important;
  }
}

@layer components {
  #__next {
    @apply h-full;
  }

  .btn {
    @apply min-h-8 h-10 rounded normal-case;
  }

  .btn-primary {
    @apply text-white;
  }

  .btn-error {
    @apply text-white;
  }

  .modal-box {
    @apply rounded;
  }
}

.react-datepicker {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #aeaeae !important;
  border-radius: 0.3rem !important;
}

.react-datepicker-popper {
  z-index: 100;
  border: 1px solid #aeaeae;
}

.react-datepicker__day-name {
  font-size: 0.8rem !important;
}

.react-datepicker__day {
  font-size: 0.8rem !important;
}

.react-datepicker__current-month {
  font-weight: bold !important;
  font-size: 0.944rem !important;
}

.SearchEvents {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.CustomCheckbox {
  z-index: 1;
}

.Input {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.react-datepicker__navigation {
  height: 0;
}

span.react-datepicker__navigation-icon {
  display: none;
}

.react-datepicker__header {
  border-bottom: 1px solid #aeaeae !important;
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
  padding-top: 8px !important;
}

.react-datepicker__month {
  margin: 0.4rem !important;
  text-align: center !important;
}

.modal-content > div > textarea {
  background-color: white;
}
